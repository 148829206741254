<template>
<div>
  <div class="fixed-top">
    <theheader></theheader>
  </div>

  <div class="section white-bg py-xl-5 mt-5">
      <div class="p-xl-0 pt-2">
        <b-container>
          <subtitle class="deepblue" subtitle="应用案例"></subtitle>
        </b-container>
      </div>

      <div class="d-flex justify-content-center py-xl-2">
        <separatormedium></separatormedium>
      </div>
      
      <thesectionrev thetitle="汽车企业：长安汽车" thedetails="以汽车用DP590、DP780材料为研究对象，为长安汽车建立一个能准确描述汽车零部件在25%偏置碰撞工况中变形与断裂行为的新型本构模型和断裂模型，并开发成为LS-DYNA直接调用的材料卡片，较传统模型预测精度大幅提高。" theillustration="changancase.png"></thesectionrev>
    </div>
    
    <div class="section white-bg py-xl-5">
      <div class="d-flex justify-content-center py-xl-2">
        <separatormedium></separatormedium>
      </div>
      <thesection head="材料制造商：鞍神、首钢等" detail="为鞍神、首钢及马钢等企业，对不同牌号双相钢、高延高强钢、热成形钢在汽车安全碰撞服役环境下进行力学及断裂性能特征测试，开发高精度材料断裂模型并进行整车应用验证。" theIllustration="zhizaoshang.png"></thesection>
    </div>

  <div class="section">
    <thefooter></thefooter>
  </div>

  <getstartedModal></getstartedModal>

  <div class="theToggle">
      <toogle></toogle>
  </div>
  
</div>
</template>

<script>
import theheader from '../components/elements/theheader.vue'
import toogle from '../components/elements/toggleMob.vue'
import subtitle from '../components/typography/subtitle.vue'
import separatormedium from "../components/typography/separatormedium";
import thefooter from '../components/elements/footer.vue'
import getstartedModal from '../components/elements/subscribe.vue'
import thesectionrev from '../components/elements/sectionrev.vue'
import thesection from "../components/elements/section";

export default {
  name: 'home',
  components: {
    theheader,
    toogle,
    subtitle,
    separatormedium,
    thefooter,
    getstartedModal,
    thesectionrev,
    thesection,
  }
}
</script>

<style>
.card-icon-circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}

</style>
