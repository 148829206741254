const team_test = {
    year: "2022 - 2023",
    members: [
        {
            name: "蒋松蔚",
            position: "",
            imagePath: "team_imgs/jiangsongwei.jpg",
            emailAddress: "btech10117.19@bitmesra.ac.in"
        },
        {
            name: "陈智然",
            position: "",
            imagePath: "team_imgs/chenzhiran.png",
            emailAddress: "pattanayak.vivek@gmail.com"
        },

        {
            name: "张宝荣",
            position: "",
            imagePath: "team_imgs/zhangbaorong.jpg",
            emailAddress: "dhruv7888@gmail.com"
        },
    ]
};

export default team_test;
