const team_data = {
    year: "2022 - 2023",
    members: [
        {
            name: "王腾腾",
            position: "",
            imagePath: "team_imgs/wangtengteng.jpg",
            emailAddress: "btech10117.19@bitmesra.ac.in"
        },
        {
            name: "陈悟果",
            position: "",
            imagePath: "team_imgs/chenwuguo.jpg",
            emailAddress: "dhruv7888@gmail.com"
        },
        {
            name: "黄荣亚",
            position: "",
            imagePath: "team_imgs/huangrongya.png",
            emailAddress: "pattanayak.vivek@gmail.com"
        },
    ]
};


export default team_data;
