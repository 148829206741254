<template>
    <div class="separator-line gray-bg d-none d-md-block"></div>
</template>
<script>
export default {
    name: "separator"
}
</script>

<style scoped>
    .separator-line {
        width: 50px;
        height: 1px;
    }
</style>