import axios from 'axios'
import {Message} from 'element-ui'

// create an axios instance
const service = axios.create({
    baseURL: "https://api.matfavor.com/",
    // baseURL: "http://127.0.0.1:8000/",
    timeout: 5000 // request timeout
})

// response interceptor
service.interceptors.response.use(
    /**
     * If you want to get http information such as headers or status
     * Please return  response => response
     */

    /**
     * Determine the request status by custom code
     * Here is just an example
     * You can also judge the status by HTTP Status Code
     */
    response => {
        const res = response.data
        if (res.code >= 200 && res.code < 400) {
            return res
        } else if (res.code >= 400) {
            Message({
                message: res.msg || '请求出错',
                type: 'error',
                duration: 3 * 1000
            })
            return Promise.reject(new Error(res.msg || '请求出错'))
        }
    },
    error => {
        // console.log(error,response) // for debug
        Message({
            message: '服务器错误',
            type: 'error',
            duration: 5 * 1000
        })
        return Promise.reject(error)
    }
)

export default service

