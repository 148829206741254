const team_steel = {
    year: "2022 - 2023",
    members: [
        {
            name: "汪健",
            position: "",
            imagePath: "team_imgs/wangjian.jpg",
            emailAddress: "btech10117.19@bitmesra.ac.in"
        },
        {
            name: "邓向星",
            position: "",
            imagePath: "team_imgs/dengxiangxing.jpg",
            emailAddress: "pattanayak.vivek@gmail.com"
        },

        {
            name: "桂林涛",
            position: "",
            imagePath: "team_imgs/guilintao.png",
            emailAddress: "dhruv7888@gmail.com"
        },
        {
            name: "孙岱",
            position: "",
            imagePath: "team_imgs/sundai.png",
            emailAddress: "satyam552000@gmail.com"
        }
    ]
};

export default team_steel;