<template>
  <div class="main-caption deepblue mb-1 mt-1">
    <el-row :gutter="30" class="panel-group">
      <el-col :span="5">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>{{ gtitle }}</span>
          </div>
          <div class="like1">
            {{ gprice }}
          </div>
        </el-card>
      </el-col>
      <el-col :span="5">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>{{ utitle }}</span>
          </div>
          <div class="like2">
            {{ uprice }}
          </div>
        </el-card>
      </el-col>
      <el-col :span="5">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>{{ dtitle }}</span>
          </div>
          <div class="like3">
            {{ diff }}
            <template>
              <span>
                <i
                    class="el-icon-star-on"
                    style="color:red"
                ></i>
              </span>
            </template>
          </div>
        </el-card>
      </el-col>
      <el-col :span="5">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>{{ remark }}</span>
          </div>
          <div class="like4">
            {{ remarkInfoForm[0].time }} {{ remarkInfoForm[0].resource }}
          </div>
        </el-card>
      </el-col>

      <el-col :span="4">
        <el-row style="margin-top: 25px">
          <el-button type="primary" @click="handleCreate()" size="medium" icon="el-icon-plus" plain>
            新增合金
          </el-button>
          <el-button
              type="warning"
              size="medium"
              icon="el-icon-edit"
              plain
              @click="handleEditRemark()"
          >
            价格信息
          </el-button>
        </el-row>
        <div class="vertical-buttons">
          <el-button
              v-if="edit"
              type="success"
              size="medium"
              icon="el-icon-check"
              style="width: 255px; height: 75px;font-size: 25px"
              @click="confirmEdit()"
          >
            提交计算
          </el-button>
          <el-button
              v-else
              type="success"
              size="medium"
              icon="el-icon-thumb"
              style="width: 255px; height: 75px; font-size: 25px"
              @click="edit=!edit"
          >
            成分设计
          </el-button>
        </div>
      </el-col>
    </el-row>

    <el-tabs v-model="activeName" type="card" style="margin-bottom: 50px">
      <el-tab-pane label="合金成本计算器" name="first">

        <el-table
            :data="originDataAlloy"
            v-loading="listLoading"
            fit
            highlight-current-row
            border
            style="width: 100%;"
            :header-cell-style="TABLE_HEADER_STYLE"
        >
          <el-table-column label="序号" align="center" type="index" width="120px"></el-table-column>

          <el-table-column align="center" label="合金名称">
            <template v-slot="{row}">
              <span>{{ row.element }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="单价（元/吨）">
            <template v-slot="{row}">
              <span>{{ row.price }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="计价标准（合金含量%）">
            <template v-slot="{row}">
              <span>{{ row.percent }}</span>
            </template>
          </el-table-column>

          <el-table-column align="center" label="22MnB5合金含量（%）">
            <template v-slot="{row}">
              <span>{{ row.compare_steel }}</span>
            </template>
          </el-table-column>


          <el-table-column min-width="100px" align="center" label="LumiSword合金含量（%）">
            <template v-slot="{row}">
              <template v-if="edit">
                <el-input v-model="row.user_steel" class="edit-input" size="small"/>
              </template>
              <span v-else>{{ row.user_steel }}</span>
            </template>
          </el-table-column>

          <el-table-column label="操作" align="center" class-name="small-padding fixed-width" width="370px">
            <template v-slot="{row,$index}">
              <div style="text-align: center; margin-left: 20px">
                <el-button type="primary" @click="handleUpdate(row)"
                           icon="el-icon-setting" size="small">
                  基本设置
                </el-button>
                <el-button type="danger" @click="handleDelete(row,$index)" icon="el-icon-delete" size="small">
                  删除
                </el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>

      </el-tab-pane>
    </el-tabs>

    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible" width="40%" class="status_change"
               :style="{ 'height': '100vh' }">
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="dataForm"
          label-width="200px"
          label-position="left"
          style="margin: 30px"
      >


        <el-form-item label="合金名称" prop="element">
          <el-input
              v-model="ruleForm.element"
          />
        </el-form-item>


        <el-form-item label="单价(元/吨)" prop="price">
          <el-input
              v-model="ruleForm.price"
              @input="checkFloat(5, ruleForm.price, ruleForm, 'price')"
          />
        </el-form-item>


        <el-form-item label="计价标准（合金含量%）" prop="percent">
          <el-input
              v-model="ruleForm.percent"
              @input="checkFloat(5, ruleForm.percent, ruleForm, 'percent')"
          />
        </el-form-item>

        <el-form-item label="22MnB5合金含量(%)" prop="compare_steel">
          <el-input
              v-model="ruleForm.compare_steel"
              @input="checkFloat(5, ruleForm.compare_steel, ruleForm, 'compare_steel')"
          />
        </el-form-item>

        <el-form-item label="LumiSword合金含量(%)" prop="user_steel">
          <el-input
              v-model="ruleForm.user_steel"
              @input="checkFloat(5, ruleForm.user_steel, ruleForm, 'user_steel')"
          />
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="dialogStatus==='create'?createData():updateData()">
          确认
        </el-button>
      </div>
    </el-dialog>

    <el-dialog :title="textRemarkMap[dialogRemarkStatus]" :visible.sync="dialogRemarkVisible" width="40%"
               class="status_change"
               :style="{ 'height': '100vh' }">
      <el-form
          :model="remarkInfoForm[0]"
          ref="remarkForm"
          label-width="200px"
          label-position="left"
          style="margin: 30px"
      >
        <el-form-item label="报价时间" prop="time">
          <el-input
              v-model="remarkInfoForm[0].time"
          />
        </el-form-item>
        <el-form-item label="价格来源" prop="resource">
          <el-input
              v-model="remarkInfoForm[0].resource"
          />
        </el-form-item>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogRemarkVisible = false">
          取消
        </el-button>
        <el-button type="primary" @click="dialogRemarkStatus==='create'?createRemark():updateRemark()">
          确认
        </el-button>
      </div>
    </el-dialog>

  </div>

</template>

<script>
import {TABLE_HEADER_STYLE} from "@/js/styles";
import {
  createAlloy,
  createRemarkInfo,
  delAlloy,
  getAlloy, getRemarkInfo,
  updateAlloy,
  updateRemarkInfo,
} from "@/js/api";
import {Message} from "element-ui";
import checkFloat from '@/utils/check-num';

export default {
  name: 'alloycost',
  mixins: [checkFloat],
  data() {
    return {
      listLoading: true,
      dialogFormVisible: false,
      dialogRemarkVisible: false,
      edit: false,
      remarkedit: false,
      originDataAlloy: [],
      activeName: 'first',
      TABLE_HEADER_STYLE,
      gprice: '',
      uprice: '',
      diff: '',
      gtitle: '22MnB5合金成本（元）',
      utitle: "LumiSword合金成本（元）",
      dtitle: "合金成本差（元）",
      remark: '价格信息',
      remarkInfoForm: [],
      dialogStatus: '',
      dialogRemarkStatus: '',
      textMap: {
        update: '编辑',
        create: '新增'
      },
      textRemarkMap: {
        update: '编辑价格信息',
        create: '新增价格信息'
      },
      ruleForm: {
        id: '',
        element: '',
        price: '',
        percent: '',
        compare_steel: '',
        user_steel: ''
      },
      rules: {
        element: [
          {required: true, message: '请填写合金名称', trigger: 'blur'},
        ],
        price: [
          {required: true, message: '请填写单价', trigger: 'blur'},
        ],
        percent: [
          {required: true, message: '请填写计价标准', trigger: 'blur'},
        ],
        compare_steel: [
          {required: true, message: '请填写22MnB5合金含量', trigger: 'blur'},
        ],
        user_steel: [
          {required: true, message: '请填写LumiSword合金含量', trigger: 'blur'},
        ],
      },
    };
  },
  created() {
    this.getAlloyList()
    this.getRemark()
  },
  watch: {
    originDataAlloy: {
      handler: 'handleCal', // 当 originDataAlloy 发生变化时调用 handleCal 方法
      deep: true // 深度监测数组内部的变化
    }
  },
  methods: {
    getAlloyList() {
      this.listLoading = true
      getAlloy().then(response => {
        this.originDataAlloy = response.data
        setTimeout(() => {
          this.listLoading = false
        }, 1.0 * 500)
      }).catch(err => {
        console.error(err)
      })
    },
    resetRulerForm() {
      this.ruleForm = {
        id: '',
        element: '',
        price: '',
        percent: '',
        compare_steel: '',
        user_steel: ''
      }
    },
    handleCreate() {
      this.resetRulerForm()
      this.dialogStatus = 'create'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    createData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          createAlloy(this.ruleForm).then(() => {
            this.originDataAlloy.unshift(this.ruleForm)
            this.dialogFormVisible = false
            Message.success({
              message: '创建成功',
              type: 'success',
              duration: 2000
            });
          })
        }
      })
    },
    handleUpdate(row) {
      this.ruleForm = Object.assign({}, row) // copy obj
      this.dialogStatus = 'update'
      this.dialogFormVisible = true
      this.$nextTick(() => {
        this.$refs['dataForm'].clearValidate()
      })
    },
    updateData() {
      this.$refs['dataForm'].validate((valid) => {
        if (valid) {
          const tempData = Object.assign({}, this.ruleForm)
          updateAlloy(this.ruleForm.id, tempData).then(() => {
            const index = this.originDataAlloy.findIndex(v => v.id === this.ruleForm.id)
            this.originDataAlloy.splice(index, 1, this.ruleForm)
            this.dialogFormVisible = false
            Message.success({
              message: '更新成功',
              type: 'success',
              duration: 2000
            });
          })
        }
      })
    },
    handleDelete(row, index) {
      delAlloy(row.id).then(res => {
        Message.success({
          message: res.msg,
          type: res.code === 200 ? 'success' : 'error'
        });
        this.originDataAlloy.splice(index, 1)
      }).catch(err => {
        console.error(err)
      })
    },
    confirmEdit() {
      this.edit = false
      Message.success({
        message: '提交成功',
        type: 'success',
        duration: 2000
      });
    },
    getRemark() {
      getRemarkInfo().then(response => {
        this.remarkInfoForm = response.data
      }).catch(err => {
        console.error(err)
      })
    },
    createRemark() {
      this.$refs['remarkForm'].validate((valid) => {
        if (valid) {
          createRemarkInfo(this.remarkInfoForm).then(() => {
            this.dialogRemarkVisible = false
            Message.success({
              message: '价格信息创建成功',
              type: 'success',
              duration: 2000
            });
          })
        }
      })
    },
    handleEditRemark() {
      if(this.remarkInfoForm[0].time === ''){
        this.dialogRemarkStatus ='create'
      }else {
        this.dialogRemarkStatus = 'update'
      }
      this.dialogRemarkVisible = true
      this.$nextTick(() => {
        this.$refs['remarkForm'].clearValidate()
      })
    },
    updateRemark() {
      this.$refs['remarkForm'].validate((valid) => {
        if (valid) {
          updateRemarkInfo(0, this.remarkInfoForm[0]).then(() => {
            this.dialogRemarkVisible = false
            Message.success({
              message: '价格信息更新成功',
              type: 'success',
              duration: 2000
            });
          })
        }
      })
    },
    handleCal() {
      // 使用 reduce 方法计算数组中各合金的 a*b 之和
      this.gprice = parseFloat(this.originDataAlloy.reduce((accumulator, currentValue) => accumulator + (currentValue.price * (currentValue.compare_steel / currentValue.percent)), 0)).toFixed(2);
      this.uprice = parseFloat(this.originDataAlloy.reduce((accumulator, currentValue) => accumulator + (currentValue.price * (currentValue.user_steel / currentValue.percent)), 0)).toFixed(2);
      this.diff = parseFloat((this.uprice - this.gprice).toFixed(2))
    }
  }
}
</script>

<style scoped lang="scss">

.edit-input {
  padding-right: 40px;
  padding-left: 40px;
}

.panel-group {
  margin-top: 18px;
}

.right-aligned-row {
  justify-content: flex-end;
}

.box-card {
  width: 400px;
  max-width: 100%;
  margin: 20px auto;
}

.like1 {
  cursor: pointer;
  display: inline-block;
  color: #20A0FF;
  font-size: 25px;
  font-weight: bold;
}

.like2 {
  cursor: pointer;
  display: inline-block;
  color: #FF4949;
  font-size: 25px;
  font-weight: bold;
}

.like3 {
  cursor: pointer;
  display: inline-block;
  color: #231815;
  font-size: 25px;
  font-weight: bold;
}

.like4 {
  cursor: pointer;
  display: inline-block;
  color: #797776;
  font-size: 25px;
  font-weight: bold;
}

.vertical-buttons {
  margin-top: 25px;
}


</style>
