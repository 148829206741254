<template>
  <div class="roundedbox">
    <div class="rounded-image" :style="boxStyle">
      <img :src="require('../../assets/partner_imgs/' + theLogo)" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'roundimage',
  props: ['theLogo', 'boxStyle'],
};
</script>

<style scoped>
.rounded-image {
  background-color: #fff;
  border-radius: 50%;
  margin: 20px 0;
  overflow: hidden;
  box-sizing: border-box;
}
.rounded-image img {
  width: 100%;
  height: 100%;
  position: relative;
}
@media screen and (max-width: 1920px) {
  .rounded-image {
    width: 140px;
    height: 140px;
  }
}
@media screen and (max-width: 1400px) {
  .rounded-image {
    width: 140px;
    height: 140px;
  }
}
@media screen and (max-width: 1200px) {
  .rounded-image {
    width: 200px;
    height: 200px;
  }
}
@media screen and (max-width: 1024px) {
  .rounded-image {
    width: 200px;
    height: 200px;
  }
}
@media screen and (max-width: 780px) {
  .rounded-image {
    width: 120px;
    height: 120px;
  }
}
@media screen and (max-width: 480px) {
  .rounded-image {
    width: 100px;
    height: 100px;
  }
}
@media screen and (max-width: 380px) {
  .rounded-image {
    width: 72px;
    height: 72px;
  }
}
@media screen and (max-width: 320px) {
  .rounded-image {
    width: 72px;
    height: 72px;
  }
}
</style>
