// 导入自己需要的组件
import { Alert, Button, Popover, Drawer, Container, Footer, Collapse, CollapseItem, InputNumber, Tree, Slider, PageHeader, Progress, Radio, Checkbox, CheckboxGroup, TimePicker, Tooltip, Menu, Submenu, MenuItem, Input, Form, FormItem, DatePicker, Table, TableColumn, Main, MessageBox, Header, Aside, DropdownMenu, Dropdown, DropdownItem, Scrollbar, Card, Row, Col, Select, Link, Tag, Option, Pagination, Loading, Divider, RadioButton, RadioGroup, Dialog, Steps, Step, Descriptions, DescriptionsItem, Tabs, TabPane, Image, Carousel, CarouselItem, Breadcrumb, BreadcrumbItem, ColorPicker, Switch, Empty, Statistic } from 'element-ui'
const element = {
    install: function (Vue) {
        Vue.use(Alert)
        Vue.use(Button)
        Vue.use(Popover)
        Vue.use(Drawer)
        Vue.use(Container)
        Vue.use(Footer)
        Vue.use(Collapse)
        Vue.use(CollapseItem)
        Vue.use(InputNumber)
        Vue.use(Tree)
        Vue.use(Slider)
        Vue.use(PageHeader)
        Vue.use(Progress)
        Vue.use(Radio)
        Vue.use(Checkbox)
        Vue.use(CheckboxGroup)
        Vue.use(TimePicker)
        Vue.use(Tooltip)
        Vue.use(Menu)
        Vue.use(Submenu)
        Vue.use(MenuItem)
        Vue.use(Input)
        Vue.use(Form)
        Vue.use(FormItem)
        Vue.use(DatePicker)
        Vue.use(Table)
        Vue.use(TableColumn)
        Vue.use(Main)
        Vue.use(Statistic)
        Vue.use(Header)
        Vue.use(Aside)
        Vue.use(DropdownMenu)
        Vue.use(Dropdown)
        Vue.use(DropdownItem)
        Vue.use(Scrollbar)
        Vue.use(Card)
        Vue.use(Row)
        Vue.use(Col)
        Vue.use(Select)
        Vue.use(Link)
        Vue.use(Tag)
        Vue.use(Option)
        Vue.use(Pagination)
        Vue.use(Loading)
        Vue.use(Divider)
        Vue.use(RadioButton)
        Vue.use(RadioGroup)
        Vue.use(Dialog)
        Vue.use(Steps)
        Vue.use(Step)
        Vue.use(Descriptions)
        Vue.use(DescriptionsItem)
        Vue.use(Tabs)
        Vue.use(TabPane)
        Vue.use(Image)
        Vue.use(Carousel)
        Vue.use(CarouselItem)
        Vue.use(Breadcrumb)
        Vue.use(BreadcrumbItem)
        Vue.use(ColorPicker)
        Vue.use(Switch)
        Vue.use(Empty)
        Vue.prototype.$message = MessageBox
    }
}

export default element
