import Vue from 'vue';
import VueRouter from 'vue-router';
import fullmenu from '../views/fullmenu';
import home from '../views/home';
import product from '../views/product';
import service from '../views/service';
import matdb from '../views/matdb';
import aboutus from '../views/aboutus';
import applicase from "../views/applicase";
import tryout from "../views/tryout";
import alloycost from "@/views/alloycost";


Vue.use(VueRouter);
const router = new VueRouter({
    routes: [{
            path: '/',
            name: 'home',
            component: home,
            meta: { transition: 'fade' },
        },
        {
            path: '/fullmenu',
            component: fullmenu,
            meta: { transition: 'fade-in-down' },
        },
        {
            path: '/product',
            component: product,
            meta: { transition: 'fade' },
        },
        {
            path: '/service',
            component: service,
            meta: { transition: 'fade' },
        },
        {
            path: '/matdb',
            component: matdb,
            meta: { transition: 'fade' },
        },
        {
            path: '/applicase',
            component: applicase,
            meta: { transition: 'fade' },
        },
        {
            path: '/aboutus',
            component: aboutus,
            meta: { transition: 'fade' },
        },
        {
            path: '/tryout',
            component: tryout,
            meta: { transition: 'fade' },
        },
        {
            path: '/alloycost',
            component: alloycost,
            meta: { transition: 'fade' },
        },
    ]
})

export default router;
