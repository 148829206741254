<template>
  <div class="tupleimg-one">
    <img data-aos="fade-up" class="rellax" data-rellax-speed="-0.1" :src="require('../../assets/images/' + imageOne)" alt="">
  </div>
</template>

<script>

export default {
    name: "tupleimg1",
    props: ['imageOne']
}
</script>

<style scoped>
    .tupleimg-one {
        margin: 0 20px;
        padding: 0 0 20px 0;
        position: relative;
        display: flex;
    }
    .tupleimg-one img{
        border-radius: 12px;
    }
    .tupleimg-one img {
        width: 480px;
        height: 314px;
        position: inhert;
        z-index: 100;
    }
    @media screen and (max-width: 1400px) {
        .tupleimg-one img{
            border-radius: 22px;
        }
        .tupleimg-one img {
            width: 380px;
            height: 248px;
        }
    }
    @media screen and (max-width: 1280px) {
        .tupleimg-one img{
            border-radius: 22px;
        }
        .tupleimg-one img {
            width: 560px;
            height: 365px;
        }
    }
    @media screen and (max-width: 1024px){
        .tupleimg-one img{
            border-radius: 22px;
        }
        .tupleimg-one img {
            width: 560px;
            height: 365px;
        }
    }
    @media screen and (max-width: 780px){
        .tupleimg-one img{
            border-radius: 22px;
        }
        .tupleimg-one img {
          width: 460px;
          height: 300px;
        }
    }
    @media screen and (max-width: 480px){
        .tupleimg-one img{
            border-radius: 12px;
        }
        .tupleimg-one img {
            width: 240px;
            height: 156px;
        }
    }
    @media screen and (max-width: 380px){
        .tupleimg-one img{
            border-radius: 12px;
        }
        .tupleimg-one img {
            width: 220px;
            height: 144px;
        }
    }
    @media screen and (max-width: 320px) {
        .tupleimg-one img {
            width: 158px;
            height: 103px;

        }
    }
</style>