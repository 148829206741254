<template>
    <b-modal class="getstarted" id="getstarted" title="Register today" content-class="shadow" size="lg" centered hide-header hide-footer >
        <div class="row">
            <div class="col-xl-6 d-none d-xl-block">
                <div class="subimg-cont">
                    <img class="sub-img" src="@/assets/images/subimage.png" alt="">
                </div>
            </div>
            <div class="col-xl-6 col-12">
                <b-form class="ml-xl-3 mr-xl-5 px-2 mx-4" @submit.prevent="subscribe">
                    <div class="py-4 mb-3">
                        <div class="py-2">
                            <h2 class="deepblue">申请试用</h2>
                        </div>
                        <div class="my-xl-2 my-2">
                            <label class="deepblue" for="inline-form-input-name">姓名</label>
                            <b-form-input
                                id="inline-form-input-name"
                                class="mb-2 mr-sm-2 mb-sm-0"
                                placeholder=""
                                v-model="inputName">
                            </b-form-input>
                        </div>
                        <div class="my-xl-2 my-2">
                            <label class="deepblue" for="inline-form-input-tel">电话</label>
                            <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                                <b-form-input
                                    id="inline-form-input-tel"
                                    placeholder=""
                                    v-model="inputTel">
                                </b-form-input>
                            </b-input-group>
                        </div>
                        <div class="my-xl-2 my-2">
                          <label class="deepblue" for="inline-form-input-comp">公司名称</label>
                          <b-input-group class="mb-2 mr-sm-2 mb-sm-0">
                            <b-form-input
                                id="inline-form-input-comp"
                                placeholder=""
                                v-model="inputComp">
                            </b-form-input>
                          </b-input-group>
                        </div>
                        <div class="mt-xl-4">
                            <b-button
                            class="my-2 gray-bg px-4 py-1 mr-2" @click="$bvModal.hide('getstarted')"
                            >取消</b-button>
                            <b-button
                            class="my-2 bluegrad-bg px-4 py-1 ml-2"
                            type="submit">提交</b-button>
                        </div>
                    </div>
                </b-form>
            </div>
        </div>
    </b-modal>
</template>

<script>
import axios from "axios";

export default {
    name: 'getstarted',
    data() {
        return {
            inputName: '',
            inputTel: '',
            inputComp: '',
        }
    },
    methods: {
        subscribe(){

          // / 进行表单校验
          if (!this.inputName) {
            this.$bvToast.toast('请填写姓名', {variant: 'danger', solid: true})
            return
          }
          if (!this.inputTel) {
            this.$bvToast.toast('请填写电话', {variant: 'danger', solid: true})
            return
          }
          if (!/^\d{11}$/.test(this.inputTel)) {
            this.$bvToast.toast('电话格式不正确', {variant: 'danger', solid: true})
            return
          }

          // 创建一个 FormData 对象，用来构造表单数据
          const form = new FormData();
          form.append('name', this.inputName);
          form.append('phone', this.inputTel);
          form.append('company', this.inputComp);

          // 使用 axios 发送 post 请求，并附带上表单数据
          axios.post('https://api.matfavor.com/concat/', form)
              .then(() => {
                this.$bvToast.toast('提交成功', {
                  variant: 'success',
                  solid: true,
                  autoHideDelay: 1000 // 设置为 1 秒钟后自动隐藏
                })
                // 关闭 dialog
                this.$bvModal.hide('getstarted')
              })
              .catch(() => {
                // 处理错误情况
                this.$bvToast.toast('提交失败，请重试', {
                  variant: 'danger',
                  solid: true,
                  autoHideDelay: 1000 // 设置为 1 秒钟后自动隐藏
                })
              });
        }
    }
}
</script>
<style scoped>
.subimg-cont {
    position: relative;
}
.subimg-cont .sub-img {
    width: 100%;
    height: 100%;
    max-width: 400px;
    max-height: 500px;
    border-radius: 2px;
}
</style>
