<template>
    <div class="separator-line lightblue-bg"></div>
</template>
<script>
export default {
    name: "separatormedium"
}
</script>

<style scoped>
    .separator-line {
        width: 80px;
        height: 3px;
    }
</style>