const team_leader = {
    year: "",
    members: [
        {
            name: "王扬卫",
            position: "平台负责人\n北京理工大学 教授 博导",
            imagePath: "team_imgs/wangyangwei.png",
            emailAddress: "lakshya.sr@gmail.com"
        },
        {
            name: "赵岩 ",
            position: "技术负责人\n博士 教授级高工",
            imagePath: "team_imgs/zhaoyan.png",
            emailAddress: "singhutkarsh902@gmail.com"
        },
    ]
};

export default team_leader;
