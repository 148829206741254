<template>
    <footer class="deepblue-bg">

      <div class="white-bg">
        <b-container fluid>
          <b-container>
            <div class="p-xl-5">

            </div>
          </b-container>
        </b-container>
      </div>

      <b-container fluid>
        <div class="pt-xl-2">
          <div class="row">
            <div class="col-xl-2 col-lg-12 col-md-12 col-12">
              <div class="text-center text-xl-left">
                <div class="py-xl-2 pb-xl-0 d-none d-lg-block">
                  <router-link to="/">
                    <img class="footer-logo" src="../../assets/logos/matfavor-logo-white.png" alt="keen communications">
                  </router-link>
                </div>
                <div class="p-2">
                  <div class="py-2">
                    <grayseparator></grayseparator>
                  </div>
                  <h5 class="gray pt-1">北京理工大学重庆创新中心</h5>
                  <div class="py-0">
                    <p class="gray">装备轻量化研究所</p>
                  </div>
                  <div class="py-0">
                    <p class="gray">重庆数元道科技有限公司</p>
                  </div>
                </div>
              </div>
              <div>
              </div>
            </div>

            <div class="col-xl-8 col-lg-12 col-md-12 col-12">
              <b-container fluid>
                <div class="">
                  <div class="">
                    <ul class="flex-column justify-content-center footer-menu p-2 pt-1">

                      <li class="footer-item pt-1 py-3"><a class="footer-link" href="#/product">产品亮点</a></li>

                      <li class="footer-item pt-1 py-3"><a class="footer-link" href="#/service">技术服务</a></li>

                      <li class="footer-item pt-1 py-3"><a class="footer-link" href="#/applicase">应用案例</a></li>

                      <li class="footer-item pt-1 py-3"><a class="footer-link" href="#/aboutus">关于我们</a></li>
                    </ul>
                  </div>
                  <b-container>
                    <div class="hvr-grow p-2">
                      <a href="#">
                        <div class="bluegrad-bg rounded-3">
                          <div class="py-2 px-4">
                            <span class="white">回到顶部</span>
                          </div>
                        </div>
                      </a>
                    </div>
                  </b-container>

                </div>
              </b-container>
            </div>

            <div class="col-xl-2 col-lg-12 col-md-12 col-12">
              <div class="text-center text-xl-left pt-1 py-3">
                <div class="p-2">
                  <h5 class="white">联系我们</h5>
                  <div class="py-2">
                    <grayseparator></grayseparator>
                  </div>
                  <div class="py-0">
                    <p class="gray">重庆市 渝北区 龙兴镇 两江协同创新区 <br>北京理工大学重庆创新中心 4栋502 </p>
                    <p class="gray">电话：153-2033-2435 (梁老师)</p>
                    <p class="gray">邮箱：liangbin.bitcq@outlook.com</p>
                  </div>
                </div>
              </div>
              <div>
              </div>
            </div>

          </div>
        </div>
      </b-container>


      <div class="btm-footer">
        <!-- change this later ^^ -->
          <div class="footer-cont yellow-bg py-3">
            <small class="deepblue">Copyright &copy; 2023: 数元道 版权所有 <a href="https://beian.miit.gov.cn/" target="_blank">渝ICP备2023004529号</a></small>
          </div>
      </div>
    </footer>
</template>

<script>
import grayseparator from '../../components/typography/grayseparator.vue'

export default {
    name: 'light-footer',
    components: {
      grayseparator,
    }
}
</script>

<style>
.btm-footer {
  bottom: .001%;
  position: relative;
  width: 100%;
  height: 100%;
}
.btm-footer .footer-cont {
  top: 5.6vh;
  position: relative;
}
.footer-menu .footer-item .footer-link {
  font-size: 1vw;
}
.footer-title {
  font-size: 22px;
}
.footer-logo {
  width: 278px;
  height: 108px;
}
.social-link {
    width: 40px;
    height: 40px;
}
@media screen and (max-width: 780px) and (max-width: 1024px){
  .footer-menu > .footer-item > .footer-link {
    font-size: 5.6vw;
  }
}
@media screen and (max-width: 320px) and (max-width: 380px) and (max-width: 480px){
  .footer-menu .footer-item .footer-link {
    font-size: 16px;
  }
  .footer-logo {
    width: 170px;
    height: 66px;
  }
  .social-link {
    width: 30px;
    height: 30px;
  }
}
</style>
