const team_cae = {
    year: "2022 - 2023",
    members: [
        {
            name: "梁宾",
            position: "",
            imagePath: "team_imgs/liangbin.png",
            emailAddress: "btech10117.19@bitmesra.ac.in"
        },
        {
            name: "高峰",
            position: "",
            imagePath: "team_imgs/gaofeng.png",
            emailAddress: "pattanayak.vivek@gmail.com"
        },

        {
            name: "范吉富",
            position: "",
            imagePath: "team_imgs/fanjifu.png",
            emailAddress: "dhruv7888@gmail.com"
        },
        {
            name: "姜子涵",
            position: "",
            imagePath: "team_imgs/jiangzihan.png",
            emailAddress: "satyam552000@gmail.com"
        },
        {
            name: "刘应波 ",
            position: "",
            imagePath: "team_imgs/刘应波.jpg",
            emailAddress: "shubhankardubey135@gmail.com"
        }
    ]
};

export default team_cae;