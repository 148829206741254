<template>
    <div class="section-v deepblue-bg">
        <div class="p-0 m-0">
            <router-link to="/">
                <img src="../assets/logos/matfavor-logo-white.png" class="nav-logo d-inline-block align-top" alt="matfavor logo" />
            </router-link>
        </div>
        <b-container>
            <div id="nav">
                <div class="nav-link">
                   <router-link to="/">
                        <div class="nav-item">
                            <div class="d-flex flex-row">
                                <div class="px-2">
                                    <i class="nav-icon yellow fi-rr-network" style="font-size: 1.3em"></i>
                                </div>
                                <div class="px-2">
                                    <h5 class="nav-title white">首页</h5>
                                </div>
                            </div>
                        </div>
                    </router-link> 
                </div>
                <div class="nav-link">
                   <router-link to="/product">
                        <div class="nav-item">
                            <div class="d-flex flex-row">
                                <div class="px-2">
                                    <i class="nav-icon yellow fi-rr-computer" style="font-size: 1.3em"></i>
                                </div>
                                <div class="px-2">
                                    <h5 class="nav-title white">产品亮点</h5>
                                </div>
                            </div>
                        </div>
                    </router-link> 
                </div>
                <div class="nav-link">
                   <router-link to="/service">
                        <div class="nav-item">
                            <div class="d-flex flex-row">
                                <div class="px-2">
                                    <i class="nav-icon yellow fi-rr-apps" style="font-size: 1.3em"></i>
                                </div>
                                <div class="px-2">
                                    <h5 class="nav-title white">技术服务</h5>
                                </div>
                            </div>
                        </div>
                    </router-link> 
                </div>
              <div class="nav-link">
                <router-link to="/applicase">
                  <div class="nav-item">
                    <div class="d-flex flex-row">
                      <div class="px-2">
                        <i class="nav-icon yellow fi-rr-shop" style="font-size: 1.3em"></i>
                      </div>
                      <div class="px-2">
                        <h5 class="nav-title white">应用案例</h5>
                      </div>
                    </div>
                  </div>
                </router-link>
              </div>
                <div class="nav-link">
                   <router-link to="/aboutus">
                        <div class="nav-item">
                            <div class="d-flex flex-row">
                                <div class="px-2">
                                    <i class="nav-icon yellow fi-rr-info" style="font-size: 1.3em"></i>
                                </div>
                                <div class="px-2">
                                    <h5 class="nav-title white">关于我们</h5>
                                </div>
                            </div>
                        </div>
                    </router-link> 
                </div>
            </div>
        </b-container>

        <b-container>

        </b-container>

        <div class="live-chat">
            <livechat></livechat>
        </div>
    </div>
</template>

<script>
import livechat from '../components/elements/liveChat.vue'

export default {
    name: 'nav',
    components: {
        livechat,
    }
}
</script>

<style scoped>
    .live-chat {
        position: fixed;
        z-index: 999999;
    }
    .nav-link {
        padding: 16px;
    }
    .nav-link a:hover {
        background-color: #0A79FF;
    }
    .nav-title {
        font-weight: 300;
        font-size: 22px;
    }
    .nav-logo {
        width: 200px;
        height: 77.8px;
    }
</style>