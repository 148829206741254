<template>
  <div>
    <!--    <herogif videoSrc="network-bg.mp4"></herogif>-->
    <div class="fixed-top">
      <theheader></theheader>
    </div>

    <div class="section-v ">
      <div class="centercomp">
        <getstarted
          class="rellax"
          data-rellax-speed="2"
          :subcaption="subDetails"
          maincap1="北京理工大学重庆创新中心"
          maincap2=""
          maincap3=""
          btntext="了解更多"
          illustration="images/bitcq.png"
        ></getstarted>
      </div>
    </div>

    <div class="section white-bg py-xl-5 py-md-3 py-2" style="height: 100%">
      <div class="pt-2">
        <b-container>
          <subtitle
            class="deepblue"
            data-aos="fade-up"
            subtitle="公司简介"
          ></subtitle>
        </b-container>
      </div>

      <div class="d-flex justify-content-center py-xl-2 pb-2">
        <separator data-aos="fade-left"></separator>
      </div>

      <b-container>
        <detailing
          class="pt-xl-3 text-left  deepblue"
          data-aos="fade-up"
          detailing="北京理工大学重庆创新中心（以下简称“中心”）是由北京理工大学和重庆市两江新区政府共建的新型高端研发机构（事业单位），是北京理工大学推进内涵式高质量发展的战略组成和重要支撑。围绕现代兵器、先进车辆、新一代电子信息、智能化和大数据、新材料、先进空天等重点科技领域，中心开展科技创新、人才培养、成果转化和国际交流等多维度工作，坚持走“扎根属地 — 优势输出 — 做实做优 — 打造一流”的特色发展道路，匹配地方产业，推动成果落地，构建共享共赢生态，服务北京理工大学“双一流”建设，助力重庆经济社会高质量发展，服务国家宏观战略需求。"
        >
        </detailing>
      </b-container>

      <div class="d-flex justify-content-center py-xl-2 pb-3 pt-3">
        <separatorsmall data-aos="fade-right"></separatorsmall>
      </div>

      <b-container class="py-xl-4 py-2">
        <div class="container">
          <div class="row p-md-4 py-2" data-aos="fade-right">
            <div class="col-md-3 col-sm-6 p-0">
              <center>
                <div class="card-icon-circle white-bg theshadow">
                  <div style="padding: 12px 0 0 0;">
                    <!--                    <i class="card-icon fi-rr-cloud-download deepblue"></i>-->
                    <roundrecimg-logo
                      class="card-icon deepblue"
                      image="pingtaiguimo.png"
                    ></roundrecimg-logo>
                  </div>
                </div>
                <div class="card-title">
                  <cardtitle class="deepblue" cardtitle="平台规模"></cardtitle>
                </div>
                <cardetails
                  class="deepblue mx-xl-2"
                  :carddetails="cardDetailsText1"
                ></cardetails>
              </center>
            </div>
            <div class="col-md-3 col-sm-6 p-0">
              <center>
                <div class="card-icon-circle white-bg theshadow">
                  <div style="padding: 12px 0 0 0;">
                    <!--                    <i class="card-icon fi-rr-broom deepblue"></i>-->
                    <roundrecimg-logo
                      class="card-icon deepblue"
                      image="chuangxinlingyu.png"
                    ></roundrecimg-logo>
                  </div>
                </div>
                <div class="card-title">
                  <cardtitle class="deepblue" cardtitle="创新领域"></cardtitle>
                </div>
                <cardetails
                  class="deepblue mx-xl-2"
                  :carddetails="cardDetailsText2"
                ></cardetails>
              </center>
            </div>
            <div class="col-md-3 col-sm-6 p-0">
              <center>
                <div class="card-icon-circle white-bg theshadow">
                  <div style="padding: 12px 0 0 0;">
                    <!--                    <i class="card-icon fi-rr-rocket deepblue"></i>-->
                    <roundrecimg-logo
                      class="card-icon deepblue"
                      image="fazhanmoshi.png"
                    ></roundrecimg-logo>
                  </div>
                </div>
                <div class="card-title">
                  <cardtitle class="deepblue" cardtitle="发展模式"></cardtitle>
                </div>
                <cardetails
                  class="deepblue mx-xl-2"
                  :carddetails="cardDetailsText3"
                ></cardetails>
              </center>
            </div>
            <div class="col-md-3 col-sm-6 p-0">
              <center>
                <div class="card-icon-circle white-bg theshadow">
                  <div style="padding: 12px 0 0 0;">
                    <!--                    <i class="card-icon fi-rr-broom deepblue"></i>-->
                    <roundrecimg-logo
                      class="card-icon deepblue"
                      image="ziyuanzhicheng.png"
                    ></roundrecimg-logo>
                  </div>
                </div>
                <div class="card-title">
                  <cardtitle class="deepblue" cardtitle="资源支撑"></cardtitle>
                </div>
                <cardetails
                  class="deepblue mx-xl-2"
                  :carddetails="cardDetailsText4"
                ></cardetails>
              </center>
            </div>
          </div>
        </div>
      </b-container>

      <b-container class="pb-xl-4">
        <div class="row d-xl-flex justify-content-center">
          <div class="col-xl-5 col-7">
            <tupleimgsmall
              data-aos="fade-up"
              largeImg="pingtaiyoushi1.png"
              smallImg="pingtaiyoushi2.jpg"
            ></tupleimgsmall>
          </div>
          <div class="col-xl-6 col-8">
            <subtitle
              class="deepblue"
              data-aos="fade-right"
              subtitle="平台优势"
            ></subtitle>
            <detailing
              class="deepblue mx-xl-2"
              data-aos="fade-left"
              detailing="依托重庆两江协同创新区的区位、生态、产业、体制等属地优势与北京理工大学学科建设、人才培养、科学研究及产业孵化等优势，形成了平台1+1>2的创新合力和快速发展强大动力。"
            ></detailing>
          </div>
        </div>
      </b-container>
    </div>

    <div class="section white-bg py-xl-5" style="height: 100%">
      <div class="pt-3">
        <b-container>
          <subtitle class="deepblue" subtitle="研发团队"></subtitle>
        </b-container>
      </div>

      <b-container>
        <div class="d-flex justify-content-center">
          <separator data-aos="fade-right"></separator>
        </div>
      </b-container>

      <b-container>
        <detailing
          class="py-3 px-4 deepblue d-none d-lg-block"
          detailing="重庆数元道科技有限公司"
        ></detailing>
      </b-container>

      <b-container fluid>
        <div class="container-xl" data-aos="fade-up">
          <div class="team-members" data-aos="fade-up" data-aos-delay="200">
            <TeamMemberCard
              v-for="member_leader in teamMembers_leader"
              :key="member_leader.name"
              :memberDetails="member_leader"
            />
          </div>
          <div class="team-members" data-aos="fade-up" data-aos-delay="220">
            <p class="year">材料力学模型开发与高精度CAE设计</p>
            <div class="d-flex justify-content-center">
              <separatorsmall data-aos="fade-right"></separatorsmall>
            </div>
            <TeamMemberCard
              v-for="member_cae in teamMembers_cae"
              :key="member_cae.name"
              :memberDetails="member_cae"
            />
          </div>

          <div class="team-members" data-aos="fade-up" data-aos-delay="240">
            <p class="year">材料数据与智能化</p>
            <div class="d-flex justify-content-center">
              <separatorsmall data-aos="fade-right"></separatorsmall>
            </div>
            <TeamMemberCard
              v-for="member_data in teamMembers_data"
              :key="member_data.name"
              :memberDetails="member_data"
            />
          </div>

          <div class="team-members" data-aos="fade-up" data-aos-delay="260">
            <p class="year">高性能热成形钢研发制备</p>
            <div class="d-flex justify-content-center">
              <separatorsmall data-aos="fade-right"></separatorsmall>
            </div>
            <TeamMemberCard
              v-for="member_steel in teamMembers_steel"
              :key="member_steel.name"
              :memberDetails="member_steel"
            />
          </div>

          <div class="team-members" data-aos="fade-up" data-aos-delay="280">
            <p class="year">材料动态力学性能测试与表征</p>
            <div class="d-flex justify-content-center">
              <separatorsmall data-aos="fade-right"></separatorsmall>
            </div>
            <TeamMemberCard
              v-for="member_test in teamMembers_test"
              :key="member_test.name"
              :memberDetails="member_test"
            />
          </div>

          <div class="team-members" data-aos="fade-up" data-aos-delay="300">
            <p class="year">柔性专家</p>
            <div class="d-flex justify-content-center">
              <separatorsmall data-aos="fade-right"></separatorsmall>
            </div>
            <TeamMemberCard
              v-for="member_professor in teamMembers_professor"
              :key="member_professor.name"
              :memberDetails="member_professor"
            />
          </div>
        </div>
      </b-container>
    </div>

    <div class="section white-bg py-xl-5 py-md-3 py-4" style="height: 100%">
      <div class="">
        <b-container>
          <div class="mx-4">
            <subtitle
              class="deepblue"
              data-aos="fade-up"
              subtitle="完备的实验体系"
            ></subtitle>
          </div>
        </b-container>
      </div>

      <div class="d-flex justify-content-center py-md-3 pt-1 pb-2">
        <separator data-aos="fade-right"></separator>
      </div>

      <b-container>
        <detailing
          class="pt-2 px-4 mx-4 text-center deepblue "
          detailing="支撑材料样品制备-工艺试验-性能测试-微观表征全生命周期研发的实验条件"
        >
        </detailing>
      </b-container>

      <b-container>
        <div class="m-md-0 mx-4 pt-3">
          <div
            class="row d-flex justify-content-center py-1 mx-md-4 m-lg-0 m-xl-0"
          >
            <div class="col-xl-6 col-lg-6 col-md-12 col-12">
              <horcards
                data-aos="fade-up"
                data-aos-delay="300"
                cardImgUrl="test_img/tensile.png"
                cardTitle="万能拉伸试验机"
                cardDetails=""
              ></horcards>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-12">
              <horcards
                data-aos="fade-up"
                data-aos-delay="324"
                cardImgUrl="test_img/fatigue.png"
                cardTitle="疲劳试验机"
                cardDetails=""
              ></horcards>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-12">
              <horcards
                data-aos="fade-up"
                data-aos-delay="346"
                cardImgUrl="test_img/dic-2d.png"
                cardTitle="准静态 2D/3D DIC系统"
                cardDetails=""
              ></horcards>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-12">
              <horcards
                data-aos="fade-up"
                data-aos-delay="346"
                cardImgUrl="test_img/dic-3d.png"
                cardTitle="高速 3D DIC系统"
                cardDetails=""
              ></horcards>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-12">
              <horcards
                data-aos="fade-up"
                data-aos-delay="390"
                cardImgUrl="test_img/xianweijing.png"
                cardTitle="金相显微镜"
                cardDetails=""
              ></horcards>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-12">
              <horcards
                data-aos="fade-up"
                data-aos-delay="390"
                cardImgUrl="test_img/yingduji.png"
                cardTitle="维氏硬度计"
                cardDetails=""
              ></horcards>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-12">
              <horcards
                data-aos="fade-up"
                data-aos-delay="300"
                cardImgUrl="test_img/SEM.png"
                cardTitle="SEM"
                cardDetails=""
              ></horcards>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-12">
              <horcards
                data-aos="fade-up"
                data-aos-delay="324"
                cardImgUrl="test_img/XRD.png"
                cardTitle="XRD"
                cardDetails=""
              ></horcards>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-12">
              <horcards
                data-aos="fade-up"
                data-aos-delay="368"
                cardImgUrl="test_img/tem.png"
                cardTitle="TEM"
                cardDetails=""
              ></horcards>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-12">
              <horcards
                data-aos="fade-up"
                data-aos-delay="390"
                cardImgUrl="test_img/xps.png"
                cardTitle="XPS"
                cardDetails=""
              ></horcards>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-12">
              <horcards
                data-aos="fade-up"
                data-aos-delay="390"
                cardImgUrl="test_img/qiepian.png"
                cardTitle="全自动逐层切片成像"
                cardDetails=""
              ></horcards>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 col-12">
              <horcards
                data-aos="fade-up"
                data-aos-delay="368"
                cardImgUrl="test_img/raman.png"
                cardTitle="Raman"
                cardDetails=""
              ></horcards>
            </div>
          </div>
        </div>
      </b-container>
    </div>

    <div class="section bluegrad-bg py-xl-5 py-md-3" style="height: 100%">
      <div class="pb-xl-3 pt-5">
        <b-container>
          <subtitle
            class="white"
            data-aos="fade-up"
            subtitle="合作伙伴"
          ></subtitle>
        </b-container>
      </div>

      <div class="d-flex justify-content-center py-xl-2 py-md-3">
        <separator data-aos="fade-right"></separator>
      </div>

      <b-container class="px-xl-5">
        <div class="px-xl-5 mx-xl-5 my-xl-2">
          <detailing
            class="px-xl-5 mx-xl-5 py-1 px-4 text-center white"
            detailing="与国内外知名汽车、材料、零部件企业、科研院所携手"
          ></detailing>
          <detailing
            class="px-xl-5 mx-xl-5 py-1 px-4 text-center white"
            detailing="以材料创新助推民族汽车工业发展"
          ></detailing>
        </div>
      </b-container>

      <b-container>
        <div class="container py-md-2">
          <div class="row d-flex justify-content-center">
            <div
              v-for="item in partnerItems"
              :key="item.theLogo"
              class="hvr-grow col-xl-2 col-lg-4 col-md-4 col-4 d-flex justify-content-center"
            >
              <roundedimg
                data-aos="fade-right"
                :data-aos-delay="item.aosDelay || '400'"
                :theLogo="item.theLogo"
                :boxStyle="item.boxStyle"
              />
            </div>
          </div>
        </div>
      </b-container>

      <b-container>
        <b-container class="py-md-4"> </b-container>
      </b-container>
    </div>

    <div class="section white-bg py-xl-5 py-md-3">
      <b-container>
        <div class="py-xl-2">
          <b-container>
            <subtitle
              class="deepblue"
              data-aos="fade-up"
              subtitle="团队愿景"
            ></subtitle>
          </b-container>
        </div>

        <div class="d-flex justify-content-center">
          <separator></separator>
        </div>

        <b-container>
          <detailing
            class="mx-xl-5 py-xl-4 m-lg-2 px-md-0 pt-3 px-4 text-lg-justify text-center deepblue"
            :detailing="yuanjingText"
          >
          </detailing>
        </b-container>

        <b-container class="mx-xl-4 pb-xl-5">
          <div
            class="row d-flex justify-content-center mb-lg-5 pb-lg-5 py-2"
            style="width: 100%"
          >
            <div class="col-xl-6 col-lg-12 col-md-12 col-12 py-0 m-0">
              <tupleimg
                imageOne="yuanjingtu1.jpg"
                imageTwo="yuanjingtu2.png"
              ></tupleimg>
            </div>
            <div class="col-xl-6 col-lg-12 col-md-12 col-12">
              <div class="mt-2">
                <b-container class="p-0 m-0">
                  <div class="p-0 m-0">
                    <subtitle class="text-left deepblue" subtitle=""></subtitle>
                  </div>
                </b-container>

                <b-container>
                  <detailing
                    class="px-4 py-3 my-3 text-left deepblue"
                    :detailing="baodaoDetail"
                  >
                  </detailing>
                </b-container>
              </div>
            </div>
          </div>
        </b-container>
      </b-container>
    </div>

    <div class="theToggle">
      <toogle></toogle>
    </div>

    <getstartedModal></getstartedModal>

    <div class="section">
      <thefooter></thefooter>
    </div>
  </div>
</template>

<script>
// import herogif from '../components/landing/herogif.vue'
import roundrecimgLogo from '../components/images/roundrecimgLogo';
import theheader from '../components/elements/theheader.vue';
import toogle from '../components/elements/toggleMob.vue';
import subtitle from '../components/typography/subtitle.vue';
import detailing from '../components/typography/detailing.vue';
import separator from '../components/typography/separator.vue';
import horcards from '../components/cards/horizontalcards.vue';
import tupleimg from '../components/images/tupleimg.vue';
import thefooter from '../components/elements/footer.vue';
import getstartedModal from '../components/elements/subscribe.vue';
import TeamMemberCard from '@/components/teams/TeamMemberCard.vue';
import team_leader from '@/assets/teamDetails/team_leader';
import team_cae from '@/assets/teamDetails/team_cae';
import team_data from '@/assets/teamDetails/team_data';
import team_steel from '@/assets/teamDetails/team_steel';
import team_test from '@/assets/teamDetails/team_test';
import team_professor from '@/assets/teamDetails/team_professor';
import separatorsmall from '../components/typography/separatorsmall';
import tupleimgsmall from '../components/images/tupleimgsmall';
import cardtitle from '../components/typography/cardtitle';
import cardetails from '../components/typography/cardetails';
import roundedimg from '../components/images/roundimg.vue';
import getstarted from '../components/landing/getstarted';

export default {
  name: 'home',
  data() {
    return {
      teamMembers_leader: team_leader.members,
      teamMembers_cae: team_cae.members,
      teamMembers_data: team_data.members,
      teamMembers_steel: team_steel.members,
      teamMembers_test: team_test.members,
      teamMembers_professor: team_professor.members,
      subDetails:
        'MatFavor材料本构与断裂建模软件，由北京理工大学\n重庆创新中心数元道科技有限公司自主研发。',
      cardDetailsText1: '员工725人\n11个科研平台\n25个科研团队',
      cardDetailsText2:
        '现代兵器、先进车辆、\n新  材  料 、先进空天、\n智能化和大数据等',
      cardDetailsText3: '院士领衔\n属地发展\n团队落地',
      cardDetailsText4: '2亿元科研设备投入\n6万方科研用房',
      yuanjingText:
        '持续攻关核心技术，研发高端、具有自主知识产权的工业软件，\n为民族汽车工业材料创新赋能！',
      baodaoDetail:
        'MatFavor符合国家战略发展方向，先后获得重庆日报、重庆两江新区等重量级媒体报道。',
      partnerItems: [
        { theLogo: 'changan.jpg', aosDelay: '600' },
        { theLogo: 'geely.jpg', aosDelay: '500' },
        {
          theLogo: 'sls.png',
          aosDelay: '500',
          boxStyle: { padding: '10px' },
        },
        { theLogo: 'zhongxin.png', aosDelay: '200' },
        { theLogo: 'cbmm.jpg', aosDelay: '200' },
        { theLogo: 'shougang.png', aosDelay: '300' },
        { theLogo: 'ma_steel.png' },
        { theLogo: 'ag.png' },
        { theLogo: 'pg.png' },
        { theLogo: 'as.png' },
        { theLogo: 'hgjt.png' },
        { theLogo: 'tagal.png' },
        { theLogo: 'bitcq.png' },
        { theLogo: 'caeri.png' },
        { theLogo: 'cqu.png' },
        { theLogo: 'xajd.jpg' },
      ],
    };
  },
  components: {
    // herogif,
    roundrecimgLogo,
    theheader,
    getstarted,
    roundedimg,
    toogle,
    subtitle,
    detailing,
    separator,
    separatorsmall,
    tupleimgsmall,
    cardtitle,
    cardetails,
    horcards,
    tupleimg,
    thefooter,
    getstartedModal,
    TeamMemberCard,
  },
};
</script>

<style scoped>
.card-icon-circle {
  width: 60px;
  height: 60px;
  border-radius: 50%;
}
.card-icon {
  font-size: 1.5vw;
}
@media screen and (max-width: 1400px) {
  .card-icon-circle {
    width: 80px;
    height: 80px;
  }
  .card-icon {
    font-size: 3.2vw;
  }
}
@media screen and (max-width: 1200px) {
  .card-icon-circle {
    width: 80px;
    height: 80px;
  }
  .card-icon {
    font-size: 3.2vw;
  }
}
@media screen and (max-width: 1024px) {
  .card-icon-circle {
    width: 120px;
    height: 120px;
  }
  .card-icon {
    font-size: 3.8em;
  }
}
@media screen and (max-width: 780px) {
  .card-icon-circle {
    width: 50px;
    height: 50px;
  }
  .card-icon {
    font-size: 3vw;
  }
}
@media screen and (max-width: 480px) {
  .card-icon-circle {
    width: 50px;
    height: 50px;
  }
  .card-icon {
    font-size: 1.4em;
  }
}
@media screen and (max-width: 380px) {
  .card-icon-circle {
    width: 50px;
    height: 50px;
  }
  .card-icon {
    font-size: 1.4em;
  }
}
@media screen and (max-width: 320px) {
  .card-icon-circle {
    width: 50px;
    height: 50px;
  }
  .card-icon {
    font-size: 1.4em;
  }
}
</style>
