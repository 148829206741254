<template>
<div>
    <div class="fixed-top">
      <theheader></theheader>
    </div>

    <div class="section white-bg py-xl-5 mt-5">
      <div class="p-xl-0 pt-2">
        <b-container>
          <subtitle class="deepblue" subtitle="技术服务"></subtitle>
        </b-container>
      </div>

      <div class="d-flex justify-content-center py-xl-2">
        <separatormedium></separatormedium>
      </div>
      <thesectionrev thetitle="材料卡片服务" thedetails="可为汽车企业及钢铁企业提供材料高精度本构与断裂模型及材料卡片研发服务，特别是针对汽车安全碰撞服役环境，已为吉利汽车、长安汽车等主流汽车企业提供技术服务。" theillustration="sandianwan.gif"></thesectionrev>
    </div>


    <div class="section white-bg py-xl-5">
      <div class="d-flex justify-content-center py-xl-2">
        <separatormedium></separatormedium>
      </div>
      <thesection head="仿真分析技术服务" detail="可基于MatFavor为客户提供定制化仿真分析技术服务，如车身压铸件服役仿真、底盘小偏执碰撞仿真、连接失效仿真以及基于机器学习的材料性能预测服务。具体服务，您可以与我们的技术专家进一步沟通。" theIllustration="caeserve.png"></thesection>
    </div>

    <div class="section white-bg py-xl-5">
      <div class="d-flex justify-content-center py-xl-2">
        <separatormedium></separatormedium>
      </div>
      <thesection head="材料动态力学性能测试及表征服务" detail="装备轻量化实验室拥有万能拉伸试验机、全场应变测量设备、三维高应变速率采集仪等高精尖仪器设备13台套，具备材料制样、金相、力学和疲劳测试能力。" theIllustration="caseimage.png"></thesection>
    </div>
    
    <div class="section white-bg">
      <thefooter></thefooter>
    </div>

    <getstartedModal></getstartedModal>
    <callustodayModal></callustodayModal>

    <div class="theToggle">
       <toogle></toogle>
    </div>

</div>
</template>

<script>
import theheader from '../components/elements/theheader.vue'
import toogle from '../components/elements/toggleMob.vue'
import subtitle from '../components/typography/subtitle.vue'
import separatormedium from '../components/typography/separatormedium.vue'
import thefooter from '../components/elements/footer.vue'
import getstartedModal from '../components/elements/subscribe.vue'
import callustodayModal from '../components/elements/callus.vue'
import thesectionrev from '../components/elements/sectionrev.vue'
import thesection from '../components/elements/section.vue'

export default {
  name: 'home',
  components: {
    theheader,
    thesectionrev,
    thesection,
    toogle,
    subtitle,
    separatormedium,
    thefooter,
    getstartedModal,
    callustodayModal
  }
}
</script>

<style scoped>
.card-icon-circle {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}
.card-icon {
      font-size: 1.5vw;
  }
@media screen and (max-width: 1400px){
  .card-icon-circle {
    width: 80px;
    height: 80px;
  }
  .card-icon {
      font-size: 3.2vw;
  }
}
@media screen and (max-width: 1200px){
  .card-icon-circle {
    width: 80px;
    height: 80px;
  }
  .card-icon {
      font-size: 3.2vw;
  }
}
@media screen and (max-width: 1024px){
  .card-icon-circle {
    width: 120px;
    height: 120px;
  }
  .card-icon {
      font-size: 3.8em;
  }
}
@media screen and (max-width: 780px){
  .card-icon-circle {
    width: 50px;
    height: 50px;
  }
  .card-icon {
      font-size: 3vw;
  }
}
@media screen and (max-width: 480px){
  .card-icon-circle {
    width: 50px;
    height: 50px;
  }
  .card-icon {
      font-size: 1.4em;
  }
}
@media screen and (max-width: 380px){
  .card-icon-circle {
    width: 50px;
    height: 50px;
  }
  .card-icon {
      font-size: 1.4em;
  }
}
@media screen and (max-width: 320px){
  .card-icon-circle {
    width: 50px;
    height: 50px;
  }
  .card-icon {
      font-size: 1.4em;
  }
}

</style>
