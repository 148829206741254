import request from '@/utils/request'

/**
 * @param send data
 */

// 提交申请信息
export function submitTry(form) {
    return request({
        url: 'concat/', method: 'post', params: {form}
    })
}

// 查询合金价格和含量
export function getAlloy() {
    return request({
        url: 'alloy/', method: 'get'
    })
}

// 删除某一条合金元素信息
export function delAlloy(id) {
    return request({
        url: 'alloy/' + id + '/', method: 'delete'
    })
}

// 新增某一条合金元素信息
export function createAlloy(data) {
    return request({
        url: 'alloy/', method: 'post', data
    })
}

// 编辑某一条合金元素信息
export function updateAlloy(id,data) {
    return request({
        url: 'alloy/'+id+'/', method: 'put', data
    })
}

// 查询合金价格和含量
export function getRemarkInfo() {
    return request({
        url: 'alloy-remark/', method: 'get'
    })
}

// 新增时间和来源信息
export function createRemarkInfo(data) {
    return request({
        url: 'alloy-remark/', method: 'post', data
    })
}

// 编辑时间和来源信息
export function updateRemarkInfo(id,data) {
    return request({
        url: 'alloy-remark/'+id+'/', method: 'put', data
    })
}





