const team_test = {
    year: "2022 - 2023",
    members: [
        {
            name: "金学军",
            position: "上海交通大学\n教授 博导 副院长",
            imagePath: "team_imgs/jinxuejun.png",
            emailAddress: "btech10117.19@bitmesra.ac.in"
        },
        {
            name: "陈登福",
            position: "重庆大学\n教授 博导",
            imagePath: "team_imgs/chendengfu.png",
            emailAddress: "pattanayak.vivek@gmail.com"
        },

        {
            name: "娄燕山",
            position: "西安交通大学\n教授 博导",
            imagePath: "team_imgs/louyanshan.png",
            emailAddress: "dhruv7888@gmail.com"
        },
        {
            name: "路洪洲",
            position: "中信集团\n博士 教授级高工",
            imagePath: "team_imgs/luhongzhou.png",
            emailAddress: "satyam552000@gmail.com"
        },
        {
            name: "陆斌",
            position: "包钢集团\n博士 教授级高工",
            imagePath: "team_imgs/lubin.png",
            emailAddress: "satyam552000@gmail.com"
        }
    ]
};

export default team_test;